import { Box } from "@mui/material";
import React from "react";
import {
  Buttons,
  DrawerCTA,
  Gallery,
  Highlights,
  Price,
  TopDrawer,
  useActiveUnit,
} from ".";
import { useUnitHighlight } from "./hooks";
import OpenHouse from "./OpenHouse";
import { getFocus, useAtom, useGet } from "state/jotai";
import { get } from "components/Favorites/Favorites.Utilities";

import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { useBreak } from "hooks/useBreak";
import { _drawer, drawer_animation_done, iframeMode } from "state/store.global";

function UnitDrawer() {
  const unit = useActiveUnit();
  const highlights = useUnitHighlight(unit);
  const unitId = parseInt(unit.getId());
  const status = unit.getStatus();
  // console.log("kp",status)
  const address = unit.getAddress();
  const townAndZipcode = unit.getTownZipCode();
  const images = unit.getImages();
  const isRent = unit.isRent();
  const price = unit.getPrice();
  const isDrawerAnimating = useAtom(drawer_animation_done)[0];
  const getIframeMode = useAtom(iframeMode)[0];
  const isMobile = useBreak("md_up");
  const drawer = useAtom(_drawer)[0];
  const isDetail = drawer.detail;
  const thumbnails = isDetail ? unit.getThumb() : [];
 const pris = unit?.data?.Data?.Pris?.Value;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        // pb: 5,
        backgroundColor: "#ffffff",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <TopDrawer
          address={`${address}, ${townAndZipcode}`}
          statusText={status.label}
          backgroundColor={status.color}
          textColor={status.id == 20 ? "#000000" : "#ffffff"}
        />

        {/* { isDrawerAnimating && <Gallery images={images} UnitDrawer /> } */}
        {/* { isDrawerAnimating && <Gallery images={images} UnitDrawer height = {isMobile ? '294px' : '321px'} /> } */}
        <Gallery
          images={images}
          thumbImg={thumbnails}
          UnitDrawer
          height={isMobile ? "294px" : "321px"}
        />

        <Buttons unitId={unitId} />

        <OpenHouse iframeMode={getIframeMode} />

        <Box sx={{ px: 2.5, mt: { md: getIframeMode ? 1 : 3.5, xs: 2 } }}>
          <Box
            sx={{
              mb: { md: getIframeMode ? 1 : 3.5, xs: 2 },
              mx: "auto",
              maxWidth: 350,
            }}
          >
            <Highlights items={highlights} />
          </Box>

          <Box
            sx={{
              mb: { md: getIframeMode ? 1.5 : 5.5, xs: 2 },
              mt: { md: getIframeMode ? 0 : 5, xs: 2 },
              textAlign: "center",
            }}
          >
            {pris && price !== '' && price !== '- ' && (
              <Price
                label={
                  isRent ? get(unit.data, "LejelejlighedsText") : "Kontatpris"
                }
                value={price}
              />
            )}
          </Box>

          <DrawerCTA unit={unit} />
        </Box>
      </Box>
      <PoweredBy />
    </Box>
  );
}

export default UnitDrawer;
